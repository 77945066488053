import React, { useState } from 'react'
import { s, colors, alpha } from '../../../style'
import { sContainer } from '../../../components/InfoCard'
import { useLangContext } from '../../../context/lang.context'
import ButtonOutline from '../../../components/ButtonOutline'

const content = {
  title: {
    pl: 'Dane do faktury',
    en: 'Billing',
  },
  text: {
    pl: [
      'Cargonite Sp. z o.o',
      'Al. Grunwaldzka 472C, 80-309 Gdańsk, Polska',
      'KRS: 0000414388 NIP: 5851464096 REGON: 221627162',
    ],
    en: [
      'Cargonite Sp. z o.o',
      'Al. Grunwaldzka 472C, 80-309 Gdańsk, Poland',
      'NCR: 0000414388 TAX: 5851464096 REGON: 221627162',
    ],
  },
}

const InfoCardLarge = () => {
  const { lang } = useLangContext()
  const [copied, setCopied] = useState(false)

  // prettier-ignore
  const onCopy = () => {
    if (copied) setCopied(false)
    else if (typeof navigator !== 'undefined' && navigator.clipboard)
      navigator.clipboard
        .writeText(
`
Cargonite Sp. z o.o
Al. Grunwaldzka 472C, 80-309 Gdańsk, Poland
KRS: 0000414388
NIP: 5851464096
REGON: 221627162
`
        )
        .then(() => setCopied(true))
        .catch((error) => setCopied(false))
  }

  return (
    <div id="invoice" css={sOuter}>
      <div css={[sContainer, sInfoCardLarge]}>
        <div css={sInner}>
          <span>{content.title[lang]}</span>
          {content.text[lang].map((text, id) => (
            <p key={id}>{text}</p>
          ))}
        </div>
        <ButtonOutline action={onCopy}>
          {' '}
          {copied
            ? lang === 'pl'
              ? 'Skopiowano'
              : 'Copied'
            : lang === 'pl'
            ? 'Skopiuj do schowka'
            : 'Copy to clipboard'}
        </ButtonOutline>
      </div>
    </div>
  )
}

const sInner = {
  [s.sm_down]: {
    marginBottom: '2rem',
  },
}

const sOuter = {
  width: '100%',
  maxWidth: 1678,
  margin: '0 auto 200px',
  padding: '0 50px 0',
  [s.xs]: {
    padding: 0,
    margin: '0 auto 50px',
  },
  scrollMarginTop: '100px',
}

const sInfoCardLarge = {
  marginBottom: 50,
  [s.xs]: { marginBottom: 37 },

  p: {
    marginTop: '1rem',
  },

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

export default InfoCardLarge
