import React from 'react'
import { useLangContext } from '../../../context/lang.context'
import { companyData, sInfoCard, sInfoCards } from '../../../components/Footer'
import InfoCard from '../../../components/InfoCard'
import { s } from '../../../style'

const XD = [
  {
    title: { pl: 'Nazwa', en: 'Name' },
    text: 'Cargonite Sp. z o.o.',
  },

  {
    title: { pl: 'Adres Rozliczeniowy', en: 'Billing Address' },
    text: 'Al. Grunwaldzka 472C, 80-309 Gdańsk, Polska ',
  },
  {
    title: { pl: 'Lokalizacja', en: 'Location' },
    text: 'Tęczowa 13, 53-601 Wrocław, PL',
    link: 'https://www.google.com/maps/dir//Cargonite,+Powsta%C5%84c%C3%B3w+%C5%9Al%C4%85skich+5,+53-332+Wroc%C5%82aw/@52.7185115,15.8396175,7z/data=!4m9!4m8!1m0!1m5!1m1!1s0x470fc3268b68dea3:0x939a35ee9910a8ca!2m2!1d17.0123841!2d51.0864469!3e0',
  },
  {
    title: { pl: 'E-mail', en: 'E-Mail' },
    text: 'office@cargonite.pl',
    link: 'mailto:office@cargonite.pl',
  },
  // {
  //   title: { pl: 'Pomoc', en: 'Help' },
  //   text: 'help@cargonite.pl',
  //   link: 'mailto:help@cargonite.pl',
  // },
  {
    title: { pl: 'Telefon', en: 'Phone' },
    text: '+48 453 019 266',
    link: 'tel:+48453019266',
  },
  {
    title: { pl: 'NIP', en: 'TAX' },
    text: '5851464096 ',
  },
  { title: { pl: 'KRS', en: 'NCR' }, text: '0000414388' },
  { title: { pl: 'REGON', en: 'REGON' }, text: '221627162' },
]

const InfoCards = () => {
  const { lang } = useLangContext()

  return (
    <div css={sOuter}>
      <div css={sInfoCards}>
        {companyData.map(({ title, text, link }, id) => (
          <div key={id} css={sInfoCard}>
            <InfoCard title={title[lang]} text={text} link={link} />
          </div>
        ))}
      </div>
      <div css={{ paddingTop: 150, [s.xs]: { paddingTop: 100 } }} />
    </div>
  )
}

const sOuter = {
  width: '100%',
  maxWidth: 1280,
  margin: '0 auto',
  padding: '0 50px',
  [s.xs]: {
    padding: '0 25px',
    margin: '0 auto',
  },
}

export default InfoCards
