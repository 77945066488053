export const introSectionContent = {
  title: {
    pl: 'Kontakt z firmą transportową',
    en: 'Contact',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: 'Skontaktuj się z nami jeśli potrzebujesz dodatkowych informacji. Chętnie odpowiemy na wszystkie pytania. Nasz wykwalifikowany zespół dobierze ofertę skierowaną specjalnie do Ciebie, jeśli szukasz transportu towarów luksusowych, agd, automotive, towarów sypkich bądź też niebezpiecznych.',
    en: 'Please contact us if you need any additional information. We are happy to answer all your questions. Our qualified team will select an offer tailored specifically to you, if you are looking for the transport of luxury goods, household appliances, automotive, loose or dangerous goods.',
  },
}

export const bannerSectionContent = {
  title: {
    pl: 'Messenger CARGONITE',
    en: 'Messenger CARGONITE',
  },
  subtitle: {
    pl: 'Błyskawiczny kontakt na',
    en: 'Contact us instantly on',
  },
  desc: {
    pl: 'Naszym klientom dajemy możliwość skontaktowania się z nami za pomocą adresu mailowego lub kontaktu telefonicznego. Oprócz tego możesz skontaktować się przez messenger - odpowiemy na wszystkie zadane nam pytania.',
    en: 'We give our clients the opportunity to contact us using an e-mail address or telephone contact. In addition, you can contact us via messenger - we will answer all your questions.',
  },
  button: {
    text: {
      pl: 'Rozmawiaj teraz!',
      en: 'Chat now!',
    },
    link: 'https://m.me/cargonite',
  },
}
