export const seo = {
  title: {
    pl: 'Kontakt CARGONITE | Firma Transportowa Wrocław',
    en: 'Contact',
  },
  description: {
    pl: 'Nasz wykwalifikowany zespół dobierze ofertę skierowaną specjalnie do Ciebie, jeśli szukasz transportu towarów luksusowych, agd, automotive, towarów sypkich bądź też niebezpiecznych.',
    en: 'Our qualified team will select an offer tailored specifically to you, if you are looking for the transport of luxury goods, household appliances, automotive, loose or dangerous goods.',
  },
}
