import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { s, colors, alpha, globals } from '../style'
import ButtonOutline from './ButtonOutline'
import Triangle from '../assets/Triangle'
import { gSubtitle, gTitle, gDesc } from '../style/config/globals'

const BannerOutline = ({
  title,
  subtitle,
  desc,
  image,
  button,
  largeWidth,
  gradientHorizontal,
}) => {
  return (
    <section css={[sOuter, largeWidth && { maxWidth: globals.maxWidth - 2 }]}>
      <div css={sInner}>
        <GatsbyImage image={image} css={globals.absolute} alt={title} />
        <div css={gradientHorizontal ? sGradientHorizontal : sGradient} />
        <Triangle
          extraCss={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: colors.main,
          }}
        />
        <span css={gSubtitle}>{subtitle}</span>
        <h4 css={gTitle}>{title}</h4>
        <p css={[gDesc, { [s.md]: { maxWidth: '50%' } }]}>{desc}</p>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 50,
            position: 'relative',
            top: 0,
            left: 0,
          }}
        >
          <ButtonOutline green link={button.link}>
            {button.text}
          </ButtonOutline>
        </div>
        <div css={sGlow} />
      </div>
    </section>
  )
}

const sOuter = {
  width: '100%',
  maxWidth: 1280,
  padding: '0 50px',
  margin: '0px auto 200px',
  [s.xs]: {
    padding: 0,
  },
  [s.sm_down]: {
    margin: '0px auto 50px',
  },
}

const sInner = {
  position: 'relative',
  width: '100%',
  height: 'max-content',
  minHeight: 350,
  display: 'flex',
  flexDirection: 'column',
  padding: 50,
  [s.xs]: {
    padding: 37,
  },

  'span, h4, p, a': {
    position: 'relative',
    inset: 'auto',
  },
}

const sGradient = {
  ...globals.absolute,
  background: `linear-gradient(to right, 
    ${colors.black} 0%, 
    ${colors.black.concat(alpha[90])} 25%,
    ${colors.black.concat(alpha[60])} 50%,
    ${colors.black.concat(alpha[0])} 75%,
    ${colors.black.concat(alpha[0])} 100%
  )`,
  [s.sm_down]: {
    background: `linear-gradient(to bottom, 
      ${colors.black} 0%, 
      ${colors.black.concat(alpha[90])} 25%,
      ${colors.black.concat(alpha[80])} 50%,
      ${colors.black.concat(alpha[60])} 70%,
      ${colors.black.concat(alpha[30])} 100%
    )`,
  },
}

const sGradientHorizontal = {
  ...globals.absolute,
  background: `linear-gradient(to top, 
    ${colors.black} 0%, 
    ${colors.black.concat(alpha[60])} 50%,
    ${colors.black.concat(alpha[0])} 75%,
    ${colors.black.concat(alpha[0])} 100%
  )`,
  [s.sm_down]: {
    background: `linear-gradient(to bottom, 
      ${colors.black} 0%, 
      ${colors.black} 25%,
      ${colors.black.concat(alpha[60])} 50%,
      ${colors.black.concat(alpha[0])} 100%
    )`,
  },
}

const sGlow = {
  pointerEvents: 'none',
  ...globals.absolute,
  zIndex: 10,
  boxShadow: `
  inset 0px 0px 25px 0px ${colors.main.concat(alpha[60])},
  0px 0px 25px 0px ${colors.main.concat(alpha[60])}, 
  inset 0px 0px 0px 2px ${colors.main.concat(alpha[100])}
   `,
}

export default BannerOutline
