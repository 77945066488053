import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { sInfoCard, sInfoCards } from '../../../components/Footer'
import { useLangContext } from '../../../context/lang.context'
import OfficeCard from '../../../components/OfficeCard'
import { s } from '../../../style'
import {
  gTitle
} from '../../../style/config/globals'

const OfficeCards = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      offices: allContentfulOffices(sort: {fields: location, order: DESC}) {
        nodes {
          address
          location
          contact {
            phones
            emails
          }
        }
      }
    }
  `)

  const officesData = query.offices.nodes

  const officeTitle = {
    pl: 'Nasze biura',
    en: 'Our offices'
  }

  return (
    <div css={sOuter}>
      <h3 css={gTitle}>{officeTitle[lang]}</h3>
      <div css={[sInfoCards, sOfficeCards]}>
        {officesData.map(({ location, address, contact }, id) => (
          <div key={id} css={sInfoCard}>
            <OfficeCard id={id} location={location} address={address} contact={contact} />
          </div>
        ))}
      </div>
      <div css={{ paddingTop: 150, [s.xs]: { paddingTop: 100 } }} />
    </div>
  )
}

const sOuter = {
  width: '100%',
  maxWidth: 1280,
  margin: '0 auto',
  padding: '0 50px',
  [s.xs]: {
    padding: '0 25px',
    margin: '0 auto',
  },
}

const sOfficeCards = {
  marginTop: '50px',
}

export default OfficeCards
