import React from 'react'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import InfoCards from './InfoCards/InfoCards'
import OfficeCards from './OfficeCards/OfficeCards'
import BannerSection from './BannerSection/BannerSection'
import FormContact from './FormContact/FormContact'
import InfoCardLarge from './InfoCards/InfoCardLarge'
import ContactPagesAll from './ContactPagesAll/ContactPagesAll'

const Contact = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <InfoCards />
      <OfficeCards />
      <ContactPagesAll />
      <FormContact page='contact' />
      <BannerSection />
      <InfoCardLarge />
    </>
  )
}

export default Contact
