import React from 'react'
import Layout from '../components/Layout'
import Contact from '../page/Contact/Contact'

const ContactPage = () => (
  <Layout>
    <Contact />
  </Layout>
)

export default ContactPage
