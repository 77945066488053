import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../../context/lang.context'
import { bannerSectionContent as content } from '../content'
import BannerOutline from '../../../components/BannerOutline'

const BannerSection = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      image: file(
        absolutePath: { regex: "/images/contact/bannerSection.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <BannerOutline
      title={content.title[lang]}
      subtitle={content.subtitle[lang]}
      desc={content.desc[lang]}
      button={{ text: content.button.text[lang], link: content.button.link }}
      image={getImage(query.image)}
      largeWidth
    />
  )
}

export default BannerSection
