import React from 'react'
import { colors, s, alpha } from '../style'

const OfficeCard = ({ id, location, address, contact, extraCss }) => {
  const emails = contact.emails
  const phones = contact.phones

  return (
    <div key={id} css={[sContainer, extraCss]}>
      <p>{location}</p>
      {address && (
        <p className='sAddress' dangerouslySetInnerHTML={{ __html: address }} />
      )}
      {phones && (
        <div css={[sContact]}>
          {phones.map((phone, id) => (
            <a key={id} href={`tel:${phone}`}>{phone}</a>
          ))}
        </div>
      )}
      {emails && (
        <div css={[sContact]}>
          {emails.map((email, id) => (
            <a key={id} href={`mailto:${email}`}>{email}</a>
          ))}
        </div>
      )}
    </div>
  )
}

const sContact = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '.25rem'
}

export const sContainer = {
  height: '100%',
  width: '100%',
  display: 'flex',
  backgroundColor: colors.container,
  padding: '50px',
  position: 'relative',
  flexDirection: 'column',

  transition: 'background-color 0.2s ease-out',

  [s.xs]: {
    padding: '37px',
  },

  span: {
    fontFamily: 'Staatliches',
    fontSize: '0.875rem',
    letterSpacing: '0.1em',
    marginBottom: '0.5rem',
    color: colors.main,
  },
  p: {
    fontSize: '2rem',
    fontFamily: 'Staatliches',
    lineHeight: '1em',
    [s.xs]: {
      fontSize: '1.5rem',
    },
    wordBreak: 'keep-all',
  },
  '.sAddress': {
    marginTop: '1rem',
    opacity: '.5',
    lineHeight: '1.25em',
    fontSize: '1.5rem',
  },
  a: {
    marginTop: '1rem',
    opacity: '.5',
    fontSize: '1.5rem',
    fontFamily: 'Staatliches',
    lineHeight: '1em',
    transition: 'all 0.2s ease-in-out',

    '&:hover': {
      color: colors.main,
      opacity: 1,
    },
  },
}

export default OfficeCard
